import React from "react"
import { Link } from "gatsby"
import { Navbar, NavbarBrand, NavItem, Nav } from "react-bootstrap"
import logo from "../images/integrant_labs_logo_white.svg"

const Navigation = ({ transparentNav, title }) => {
  return (
    <div className={`header-wrapper`}>
      <Navbar
        variant="dark"
        collapseOnSelect
        expand="md"
        className={transparentNav && `transparentNav`}
      >
        <NavbarBrand className="nav-icon">
          <Link to={"/"} className={`logo`}>
            <img src={logo} height="35" alt={title} />
            <div className={`logo-text`}>Integrant Labs</div>
          </Link>
        </NavbarBrand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className={`mr-0 mr-md-4 ml-auto d-flex align-items-center`}>
            <NavItem className={`p-1`}>
              <Link to={"/services"}>Services</Link>
            </NavItem>
            <NavItem className={`p-1`}>
              <Link to={"/labs"}>Labs</Link>
            </NavItem>
            <NavItem className={`p-1`}>
              <Link to={"/blog"}>Blog</Link>
            </NavItem>
          </Nav>
          <Nav className="d-flex align-items-center">
            <NavItem className={`p-1 p-md-0`}>
              <a href={"https://twitter.com/IntegrantLabs"}>
                <i className="fab fa-twitter"></i>
              </a>
            </NavItem>
            <NavItem className={`p-1 p-md-0`}>
              <a href={"https://www.linkedin.com/company/integrant-labs/about"}>
                <i className="fab fa-linkedin"></i>
              </a>
            </NavItem>
            <NavItem className={`p-1 p-md-0`}>
              <a href={"https://github.com/integrant-io"}>
                <i className={`fab fa-github`} />
              </a>
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

export default Navigation

import React, { useState, Children, cloneElement } from "react"
import Navigation from "../components/navigation"
import logo from "../images/Integrant_Labs_Logo_Text_Light.png"

const Layout = ({ children }) => {
  const title = "Integrant Labs"
  const [transparentNav, setTransparentNav] = useState(false)
  return (
    <div className={`wrapper`}>
      <Navigation
        style={{ boxShadow: `0 8px 6px -6px #999` }}
        title={title}
        transparentNav={transparentNav}
      />
      <div className={`content-wrapper`}>
        <main>
          {Children.map(children, child => {
            return cloneElement(child, {
              transparentNav: transparentNav,
              setTransparentNav: setTransparentNav,
            })
          })}
        </main>
      </div>
      <div className={`footer-wrapper`}>
        <div className={`container-fluid`}>
          <div className={`row`}>
            <div
              className={`col-md-4 order-3 order-md-1 d-flex align-items-end justify-content-center justify-content-md-start`}
            >
              <div className={`footer-left`}>
                © {new Date().getFullYear()}, Integrant Labs
              </div>
            </div>
            <div
              className={`col-md-4 order-2 d-flex align-items-end justify-content-center`}
            >
              <div className={`footer-center`}>
                <img src={logo} height="70" alt={title} />
                <p>ABN: 11635038983</p>
              </div>
            </div>
            <div
              className={`col-md-4 order-1 order-md-3 d-flex align-items-center justify-content-center justify-content-md-end pr-md-5`}
            >
              <div className={`footer-right`}>
                <ul className={`text-center text-md-left`}>
                  <li>
                    <i className={`fas fa-phone-alt`}></i>
                    &nbsp; Phone:{" "}
                    <small>
                      <a href={`tel:+61415811163`}>+61 415 811 163</a>
                    </small>
                  </li>
                  <li>
                    <i className={`fas fa-envelope`}></i>
                    &nbsp; Email:{" "}
                    <small>
                      <a href={`mailto:sales@integrant.io`}>
                        sales@integrant.io
                      </a>
                    </small>
                  </li>
                  <li>
                    <hr />
                    <div
                      className={`grouped-links justify-content-around justify-content-md-start`}
                    >
                      <a
                        href={`https://twitter.com/IntegrantLabs`}
                        className={`pr-0 pr-md-3`}
                      >
                        <i className={`fab fa-twitter`} />
                      </a>
                      <a
                        href={`https://www.linkedin.com/company/integrant-labs/about`}
                        className={`pr-0 pr-md-3`}
                      >
                        <i className={`fab fa-linkedin`} />
                      </a>
                      <a href={"https://github.com/integrant-io"}>
                        <i className={`fab fa-github`} />
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout
